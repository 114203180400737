import React from 'react'
import SEO from '../components/seo'
import * as commonPageStyles from '../styles/common-page.module.css'

const LekcjeOnlinePage = () => (
  <>
    <SEO title="Nauka gry na gitarze i śpiewu online - MusicSpot" />
    <section className="sub-page-header">
      <div className="wrapper">
        <h1>Lekcje online</h1>
      </div>
    </section>

    <div className={`${commonPageStyles.sectionsContainer} wrapper`}>
      <p>
        Coraz bardziej powszechny staje się system pracy zdalnej. Zapewnia on
        bezpieczeństwo, oszczędza czas, a odległość nie stanowi już żadnego
        problemu. Co więcej, do przeprowadzenia lekcji śpiewu on-line i lekcji
        gitary on-line, nie potrzebny jest specjalistyczny sprzęt. W zupełności
        wystarczające są parametry komputerów, tabletów czy smartfonów, z
        których wszyscy korzystamy. Dodatkowym atutem, którym możemy zachęcić do
        skorzystania z oferty zajęć zdalnych, jest wieloletnie doświadczenie
        pracy trybem on-line, dzięki czemu lekcje nie odbiegają jakością od
        zajęć stacjonarnych.
      </p>

      <h2>Lekcje śpiewu on-line</h2>
      <p>
        Do przeprowadzenia zajęć wokalnych on-line potrzebny jest komputer lub
        tablet z kamerą oraz smartfon, niezbędny do pracy na konkretnych
        przykładach muzycznych. Zajęcia prowadzone są w taki sposób, by
        pojawiająca się latencja nie stała się przeszkodą, a odpowiednio
        wykorzystana mogła pracować na naszą korzyść. Dzięki doświadczeniu w
        zakresie pracy on-line, jesteśmy w stanie zapewnić najwyższą jakość
        oferowanych usług.
      </p>

      <h2>Lekcja gitary on-line</h2>
      <p>
        Specyfika pracy podczas lekcji gitary on-line stawia nowe wyzwania
        zarówno przed prowadzącymi zajęcia jak i odbiorcami. Z wielu względów
        zajęcia prowadzone w ten sposób mogą być o wiele efektywniejsze. Praca
        systemem on-line wymusza wykonywanie bardzo konkretnych zadań, wzmagając
        tym samym skupienie i niejednokrotnie prowadząc do szybszych i
        skuteczniejszych efektów w nauce. Występująca latencja nie pozwala na
        równoczesną grę, jednak powołując się na nasze doświadczenie, umiejętne
        ułożenie zadań w trakcie prowadzenia lekcji, powoduje, że zajęcia są
        równie wartościowe co zajęcia stacjonarne.
      </p>

      <h2>Darmowa lekcja on-line</h2>
      <p>
        Starając się zapewnić najwyższą jakość usług, zapraszamy do
        niezobowiązującej, darmowej 30-minutowej lekcji próbnej. Wierzymy, że
        pozwoli na przetestowanie nowej formy pozyskiwania wiedzy i pomoże
        podjąć decyzję o rozpoczęciu nauki w Music Spot.
      </p>
    </div>
  </>
)

export default LekcjeOnlinePage
